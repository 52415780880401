import { useContext } from "react";
import { EditorHTML } from "../common/EditorHTML";
import { AppConfigContext } from "../../context/AppConfigContext";

const LegalSection = () => {
  const appconfig = useContext(AppConfigContext);
  const { setAppConfigProperty } = useContext(AppConfigContext);

  const handleChangePageContent = (key, content) => {
    setAppConfigProperty(key, {
      ...appconfig[key],
      content,
    });
  };

  const hasHTMLDescription = (text) => {
    const htmlRegex = /<([a-z]+)\s*\/?[^>]*>/i;
    return htmlRegex.test(text);
  };

  const renderPrivacyPage = () => {
    const { privacy_policy } = appconfig;
    let content =
      privacy_policy && privacy_policy !== null ? privacy_policy : "";

    if (!hasHTMLDescription(content)) {
      content = `<p>${content}</p>`;
    }

    return (
      <EditorHTML
        content={content}
        setContent={(content) => {
          handleChangePageContent("privacy_policy", content);
        }}
      />
    );
  };

  const renderTermsPage = () => {
    const { terms_and_conditions } = appconfig;
    let content =
      terms_and_conditions && terms_and_conditions !== null
        ? terms_and_conditions
        : "";

    if (!hasHTMLDescription(content)) {
      content = `<p>${content}</p>`;
    }
    return (
      <EditorHTML
        content={content}
        setContent={(content) => {
          handleChangePageContent("terms_and_conditions", content);
        }}
      />
    );
  };

  return (
    <>
      <div className="col-6 ps-0 mb-3">
        <h4>Aviso de Privacidad</h4>
        <div className="card p-2" style={{ cursor: "text" }}>
          {renderPrivacyPage()}
        </div>
      </div>

      <div className="col-6 pe-0 mb-3">
        <h4>Terminos y condiciones</h4>
        <div className="card p-2" style={{ cursor: "text" }}>
          {renderTermsPage()}
        </div>
      </div>
    </>
  );
};

export default LegalSection;
