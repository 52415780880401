import React, { useEffect } from "react";
import { Router, navigate } from "@reach/router";
import Login from "./views/Login";
import Panel from "./views/Panel";
import { useContext } from "react";
import { UserContext } from "./context/UserContext";
import Modal from "./components/common/Modal";
import SuccessAlert from "./components/common/SuccessAlert";
import ErrorAlert from "./components/common/ErrorAlert";
import Loading from "./views/Loading";
import { AppConfigContext } from "./context/AppConfigContext";
import OffCanvas from "./components/global/OffCanvas";
import { setupTimezone } from "./utils/dates";

const Main = () => {
  const { user, userLoggedIn, setupInterceptors } = useContext(UserContext);

  const config = useContext(AppConfigContext);

  useEffect(() => {
    setupInterceptors();
    userLoggedIn();
    if (config.timezone && config.timezone !== null) {
      setupTimezone(config.timezone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.role === "coach") {
      navigate("/myadmin/clases/");
    }

    const { getAdminAppConfig } = config;
    if (["super_admin", "admin"].includes(user?.role)) {
      getAdminAppConfig();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    handleColors();
    handleMetaTags();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  const handleColors = () => {
    const props = ["primary", "accent", "dark", "gray"];
    props.forEach((key) => {
      const color = config[key];
      document.documentElement.style.setProperty(`--${key}`, color);
    });
  };

  const handleMetaTags = () => {
    const {
      favicon,
      social_media_image,
      business_name,
      tagline,
      head_tracking_codes,
      body_tracking_codes,
      S3_ENDPOINT,
    } = config;

    var link = document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = `${S3_ENDPOINT}/${favicon}`;
    }

    document.title = `Admin ${business_name}`;

    if (tagline) {
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", tagline);
    }

    if (social_media_image) {
      document
        .querySelector('meta[name="og:image"]')
        .setAttribute("content", social_media_image);
    }

    if (head_tracking_codes) {
      const script = document.querySelector("script#head_tacking_codes");
      script.innerHTML = `
        try {
        
          ${head_tracking_codes}
        } catch (error) {
          
        }
      `;
    }

    if (body_tracking_codes) {
      const script = document.querySelector("script#body_tacking_codes");
      script.innerHTML = `
        try {
        
          ${body_tracking_codes}
        } catch (error) {
          
        }
    `;
    }
  };

  return (
    <div className="container-fluid px-0 vh-100">
      <Router>
        <Login path="/entrar" default />
        {user !== null ? (
          <Panel path="/myadmin/*" />
        ) : (
          <Loading path="/myadmin/*" />
        )}
      </Router>
      <Modal />
      <OffCanvas />
      <SuccessAlert />
      <ErrorAlert />
    </div>
  );
};

export default Main;
