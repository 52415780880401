import React, { useContext, useEffect, useState } from "react";
import { SingleClassContext } from "../../context/SingleClassContext";
import { ModalContext } from "../../context/ModalContext";
import { UserContext } from "../../context/UserContext";
import AgregarAsistente from "../../components/clases/AgregarAsistente";
import PanelTitle from "../../components/global/PanelTitle";
import { ReservationsContext } from "../../context/ReservationsContext";
import AsistentesTable from "../../components/asistentes/AsistentesTable";
import SingleClassData from "../../components/single_class/SingleClassData";
import { WaitlistsContext } from "../../context/WaitlistsContext";
import { getValue } from "../../utils";
import { AppConfigContext } from "../../context/AppConfigContext";

const AdminAsistentes = ({ single_class_id }) => {
  const { clase, clearClase, postPayment, getAsistentes, updateGuestName } =
    useContext(SingleClassContext);
  const { waitlist, getSingleClassWaitlist } = useContext(WaitlistsContext);
  const { postGear, postAttend } = useContext(ReservationsContext);
  const { eliminarReservacion } = useContext(ReservationsContext);
  const { modalComponent } = useContext(ModalContext);
  const appconfig = useContext(AppConfigContext);
  const { user } = useContext(UserContext);

  const [view, setView] = useState("reservas");

  useEffect(() => {
    fetchAsistentes();
    return clearClase;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [single_class_id]);

  const renderAsistentes = () => {
    if (clase && clase !== null && view === "reservas") {
      return clase.asistentes;
    }
    if (view === "waitlist") {
      return waitlist;
    }
    return [];
  };

  const fetchAsistentes = () => {
    getAsistentes(single_class_id);
    getSingleClassWaitlist(single_class_id);
  };

  const addAsistente = () => {
    modalComponent(
      "Agregar Asistente",
      <AgregarAsistente single_class_id={single_class_id} />
    );
  };

  const confirmCancel = (customer) => {
    modalComponent(
      "¿Cancelar reservacion?",
      <div className="container-fluid">
        <p>
          ¿Estás segura que deseas cancelar la reservacion de {customer.name}?
          Esta acción NO puede deshacerse.
        </p>
        <button
          className="btn btn-danger"
          onClick={() =>
            eliminarReservacion(customer.class_reservation_id, () =>
              getAsistentes(single_class_id)
            )
          }
        >
          Eliminar
        </button>
      </div>
    );
  };

  const renderClase = () => {
    if (clase && clase !== null) {
      return <SingleClassData single_class={clase} />;
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="contiainer-fluid px-3">
      <PanelTitle
        title="Clase Presencial"
        onClick={addAsistente}
        hideButton={user.role === "coach"}
      />
      <div className="card p-3 shadow-sm mb-3">{renderClase()}</div>
      {getValue(appconfig, "waitlist_enabled", "booleam") && (
        <div className="btn-group w-100 my-1">
          <div
            onClick={() => setView("reservas")}
            className={`btn btn-${
              view === "reservas" ? "primary" : "outline-primary"
            }`}
          >
            Reservas
          </div>
          <div
            onClick={() => setView("waitlist")}
            className={`btn btn-${
              view === "waitlist" ? "primary" : "outline-primary"
            }`}
          >
            Lista de Espera
          </div>
        </div>
      )}
      <AsistentesTable
        asistentes={renderAsistentes()}
        postPayment={postPayment}
        postAttend={postAttend}
        handleCallback={fetchAsistentes}
        single_class_id={single_class_id}
        updateGuestName={updateGuestName}
        confirmCancel={confirmCancel}
        postGear={postGear}
        user={user}
        clase={clase}
      />
    </div>
  );
};

export default AdminAsistentes;
