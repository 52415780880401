import React, { useContext } from "react";
import {
  getFriendlyLocalDateTimeString,
  getLocalMomentObject,
} from "../../utils/dates";
import { PurchasesContext } from "../../context/PurchasesContext";
import { ModalContext } from "../../context/ModalContext";
import PurchaseRefundForm from "./PurchaseRefundForm";
import EditPurchaseForm from "./EditPurchaseForm";
import StatusBadge from "../common/StatusBadge";
import { formatMonto } from "../../utils";
import { Link } from "@reach/router";

const PurchaseRow = ({ user, paquete, hideColumns }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);

  const { setPurchase } = useContext(PurchasesContext);

  const handleCancel = () => {
    clearModal();
    setPurchase(null);
  };

  const handleEdit = () => {
    setPurchase(paquete);
    modalComponent(
      "Editar Compra",
      <EditPurchaseForm handleCancel={handleCancel} />
    );
  };

  const canEdit = () => {
    return paquete.status !== "revoked";
  };

  const renderPaymentMethod = () => {
    if (paquete.payment_method && paquete.payment_method !== null) {
      return paquete.payment_method.name;
    }
  };

  const renderExpiration = () => {
    let createdAt = getLocalMomentObject(paquete.createdAt);
    if (paquete.subscription_id !== null) {
      if (paquete.status === "active") {
        let day = createdAt.day();
        let nextMonth = getLocalMomentObject();
        let currDay = getLocalMomentObject().day();
        if (day < currDay) {
          nextMonth = getLocalMomentObject(nextMonth, "M").add(
            paquete.subscription_interval,
            paquete.subscription_period
          );
        }
        return nextMonth
          .startOf("month")
          .add(day, "days")
          .format("DD MMM YYYY");
      }
      return createdAt
        .add(paquete.subscription_interval, paquete.subscription_period)
        .format("DD MMM YYYY");
    }
    return createdAt
      .add(paquete.expiration_days, "days")
      .format("DD MMMM YYYY");
  };

  const renderType = () => {
    if (!Array.isArray(hideColumns) || !hideColumns.includes("type")) {
      return (
        <td>{paquete.invoice_id ? <i className="fa fa-undo"></i> : ""}</td>
      );
    }
  };

  const renderCustomer = () => {
    if (
      paquete.customer &&
      paquete.customer !== null &&
      (!Array.isArray(hideColumns) || !hideColumns.includes("customer"))
    ) {
      return (
        <td>
          <Link to={`/myadmin/customer/${paquete.customer.customer_id}`}>
            <i className="fa fa-eye"></i> {paquete.customer.name}{" "}
            {paquete.customer.last_name}
          </Link>
        </td>
      );
    }
  };

  const isFirstPurchase = () => {
    if (paquete.customer && paquete.customer !== null) {
      if (Array.isArray(paquete.customer.purchases)) {
        return paquete.customer.purchases.length === 1;
      }
    }
  };

  const renderDiscountCode = () => {
    if (paquete.discount && paquete.discount !== null) {
      return paquete.discount.code;
    }
  };

  const renderClassPackage = () => {
    if (paquete.class_package && paquete.class_package !== null) {
      return paquete.class_package.title;
    }
  };

  const isPayPal = () => {
    if (paquete.payment_method_id === 3) {
      let link = "";
      let text = "";

      if (paquete.subscription_id !== null) {
        link = `https://www.paypal.com/billing/subscriptions/${paquete.subscription_id}`;
        text = "Subscripciones";
      } else if (paquete.order_id !== null) {
        link = `https://www.paypal.com/activity/payment/${paquete.order_id}`;
        text = "Pagos";
      }

      return (
        <a
          href={link}
          className="btn px-2 btn btn-outline-secondary dropdown-item "
          rel="noreferrer"
          target="_blank"
        >
          <i
            className="fab fa-paypal me-2"
            style={{ width: "20px", height: "20px" }}
          ></i>
          {text}
        </a>
      );
    }
  };

  const isStripe = () => {
    if (paquete.payment_method_id === 1) {
      let link = "";
      let text = "";

      if (paquete.subscription_id !== null) {
        link = `https://dashboard.stripe.com/subscriptions/${paquete.subscription_id}`;
        text = "Subscripciones";
      } else if (paquete.order_id !== null) {
        link = `https://dashboard.stripe.com/payments/${paquete.order_id}`;
        text = "Pagos";
      }

      return (
        <li>
          <a
            href={link}
            className="btn px-2 btn btn-outline-secondary dropdown-item "
            rel="noreferrer"
            target="_blank"
          >
            <i
              className="fab fa-stripe-s me-2"
              style={{ width: "20px", height: "20px" }}
            ></i>
            {text}
          </a>
        </li>
      );
    }
  };

  const renderEditBtn = () => {
    if (canEdit()) {
      return (
        <li>
          <button className="dropdown-item px-2 " onClick={handleEdit}>
            <i
              className="fas fa-edit text-primary me-2"
              style={{ width: "20px", height: "20px" }}
            />
            Editar
          </button>
        </li>
      );
    }
  };

  const handleRefund = () => {
    modalComponent(
      "Reembolsar Compra",
      <PurchaseRefundForm
        purchase={paquete}
        handleCancel={() => {
          clearModal();
        }}
      />
    );
  };

  const renderRefundBtn = () => {
    if (paquete.status === "completed" && paquete.subscription_id === null) {
      return (
        <li>
          <button className="dropdown-item px-2 " onClick={handleRefund}>
            <i
              className="fas fa-undo text-success me-2"
              style={{ width: "20px", height: "20px" }}
            />
            Reembolso
          </button>
        </li>
      );
    }
  };

  const renderActions = () => {
    return (
      <div className="dropdown">
        <button
          className="btn btn-outline-primary border border-1 dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Opciones
        </button>

        <ul className="dropdown-menu py-0">
          {renderEditBtn()}
          {isStripe()}
          {isPayPal()}
          {renderRefundBtn()}
        </ul>
      </div>
    );
  };

  const renderCustomerTags = () => {
    if (Array.isArray(paquete?.customer?.tags)) {
      return paquete.customer.tags.map((tag) => {
        return (
          <span key={tag.tag_id} className="badge badge-pill bg-secondary me-1">
            {tag.content}
          </span>
        );
      });
    }
  };

  return (
    <tr className="small border-bottom align-middle hover-light">
      <td>
        {paquete.invoice_id ? paquete.invoice_id : paquete.purchase_id}{" "}
        {paquete.admin_enabled && <i className="fa fa fa-user-shield"></i>}{" "}
        {isFirstPurchase() && <i className="fa fa-star text-warning"></i>}
      </td>
      {renderType()}
      {renderCustomer()}
      <td className="px-0">{renderCustomerTags()}</td>
      <td>{renderClassPackage()}</td>
      <td>{getFriendlyLocalDateTimeString(paquete.createdAt)}</td>
      <td>
        {paquete.is_gift && <i className="fas fa-gift me-1"></i>}
        {["admin", "super_admin"].includes(user.role) && (
          <>
            {"$"}
            {formatMonto(paquete.amount)} MXN
          </>
        )}
      </td>
      <td>{renderExpiration()}</td>
      <td>{renderPaymentMethod()}</td>
      <td>
        <StatusBadge status={paquete.status} date={paquete.updatedAt} />
      </td>
      <td>{renderDiscountCode()}</td>
      <td>{renderActions()}</td>
    </tr>
  );
};

export default PurchaseRow;
