import { getFriendlyLocalDateString } from "./dates";

export const FILES_ENDPOINT = `https://istudioapp.s3.us-west-1.amazonaws.com/`;

export const generateRandomKey = () => {
  return '_' + Math.random().toString(36).substring(2, 9);
}

export const showOffcanvas = () => {
  const button = document.getElementById("offcanvas-button");
  if (button && button !== null) {
    button.click();
  } else {
    const newButton = document.createElement("button");
    newButton.attributes.href = "#offcanvas";
    newButton.id = "offcanvas-button";
    newButton.setAttribute("data-bs-toggle", "offcanvas");
    newButton.setAttribute("data-bs-target", "#offcanvas");
    newButton.setAttribute("aria-controls", "offcanvas");
    newButton.type = 'button'
    newButton.style.display = "none";
    document.body.appendChild(newButton);
    newButton.click();
  }
};

export const hideOffcanvas = () => {
  let offcanvas = document.getElementsByClassName("offcanvas")[0];
  let backdrop = document.getElementsByClassName("offcanvas-backdrop")[0];

  if (offcanvas && offcanvas !== null) {
    offcanvas.classList.remove("show");
    document.body.style = "";
    if (backdrop && backdrop !== null) {
      backdrop.remove();
    }
  }
};

export const searchRows = (query, rows) => {
  if (!rows) return;
  if (isNaN(query)) query = query.toLowerCase();
  let searchResult = rows.filter((row) => {
    let result = Object.keys(row).filter((column) => {
      if (Array.isArray(row[column])) {
        return row[column].filter((subcolumn) => {
          if (isNaN(subcolumn)) {
            if (subcolumn.toLowerCase().includes(query)) return row;
          } else if (subcolumn === query) return row;
          return null;
        });
      }
      if (isNaN(row[column])) {
        if (String(row[column]).toLowerCase().includes(query)) {
          return row;
        }
      } else if (String(row[column]) === query) {
        return row;
      } else if (Number(row[column]) === Number(query)) {
        return row;
      }
      return null;
    });
    return result.length > 0;
  });
  return searchResult;
};

export const getArgs = (args) => {
  if (args && args !== null) {
    const array = Object.keys(args)
      .map((key) => {
        if (args[key] && args[key] !== null && args[key] !== "") {
          return `${key}=${args[key]}`;
        }
        return null;
      })
      .filter((arg) => arg !== null);
    if (array.length > 0) {
      return `${array.join("&")}`;
    }
  }
  return "";
};

export const calcularTotal = (productos) => {
  if (productos && productos !== null) {
    let total = 0;
    productos.forEach((producto) => {
      total += producto.precio * producto.cantidad;
    });
    return total;
  }
  return 0;
};

export function formatMonto(monto) {
  monto = parseFloat(monto);
  if (!monto || monto === null || isNaN(monto)) monto = 0;
  return numberWithCommas(monto);
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const hideModal = () => {
  const button = document.getElementById("main-button");
  if (button && button !== null) {
    button.click();
  }
};

export const hideMobileMenu = () => {
  const menu = document.getElementsByClassName("drawer-menu")[0];
  if (menu && menu !== null) {
    menu.style.left = "-100vw";
  }
};

export const showModal = () => {
  const button = document.getElementById("main-button");
  if (button && button !== null) {
    button.click();
  } else {
    const newButton = document.createElement("button");
    newButton.attributes.href = "#modal";
    newButton.id = "main-button";
    newButton.setAttribute("data-toggle", "modal");
    newButton.setAttribute("data-target", "#modal");
    newButton.setAttribute("data-bs-toggle", "modal");
    newButton.setAttribute("data-bs-target", "#modal");
    newButton.style.display = "none";
    document.body.appendChild(newButton);
    newButton.click();
  }
};

export const menuitems = [
  {
    name: "Calendario",
    handle: "/myadmin",
  },
  {
    name: "Reservaciones",
    handle: "/myadmin/reservaciones",
  },
  {
    name: "Paquetes",
    handle: "/myadmin/paquetes",
  },
  {
    name: "Órdenes",
    handle: "/myadmin/orders",
  },
  {
    name: "Clientes",
    handle: "/myadmin/customers",
  },
];

export const cuenta = [
  {
    name: "Mi Información",
    handle: "/myadmin/informacion",
  },
  {
    name: "Mis Compras",
    handle: "/myadmin/pagos",
  },
];

export const adminitems = [
  {
    name: "Ventas",
    handle: "/myadmin/orders",
  },
  {
    name: "Clases",
    handle: "/myadmin/clases",
  },
  {
    name: "Asistentes",
    handle: "/myadmin/asistentes",
  },
  {
    name: "Clientes",
    handle: "/myadmin/customers",
  },
  {
    name: "Paquetes",
    handle: "/myadmin/paquetes",
  },
  {
    name: "Coaches",
    handle: "/myadmin/coaches",
  },
  {
    name: "Especiales",
    handle: "/myadmin/especiales",
  },
  {
    name: "Descuentos",
    handle: "/myadmin/descuentos",
  },
  {
    name: "Ubicaciones",
    handle: "/myadmin/ubicaciones",
  },
  {
    name: "Usuarios",
    handle: "/myadmin/usuarios",
  },
];

export const analiticasitems = [
  {
    name: "Inscritos",
    handle: "/myadmin/analytics/inscritos",
  },
  {
    name: "Ingresos",
    handle: "/myadmin/analytics/ingresos",
  },
  {
    name: "Instructores",
    handle: "/myadmin/analytics/instructores",
  },
  {
    name: "Paquetes",
    handle: "/myadmin/analytics/paquetes",
  },
  {
    name: "Reservaciones",
    handle: "/myadmin/analytics/reservaciones",
  },
  {
    name: "Mensuales",
    handle: "/myadmin/analytics/mensuales",
  },
];

export const coachitems = [
  {
    name: "Clases",
    handle: "/myadmin/asistentes",
  },
  {
    name: "Asistentes",
    handle: "/myadmin/asistentes",
  },
];

export const randomString = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getCardType = (number) =>
  String(number) === "3"
    ? "amex"
    : String(number) === "4"
    ? "visa"
    : "mastercard";

export const cardStyle = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#32325d",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const getFormDataFile = (file, key) => {
  const formData = new FormData();
  formData.append(key, file);
  return formData;
};

export const getTotalCompras = (customer) => {
  let total = 0;
  if (Array.isArray(customer.invoices)) {
    customer.invoices.forEach((purchase) => {
      if (
        purchase.status !== "pending" &&
        purchase.status !== "failed" &&
        purchase.status !== "refunded"
      ) {
        if (!isNaN(parseFloat(purchase.amount))) {
          total += parseFloat(purchase.amount);
        }
      }
    });
  }
  if (Array.isArray(customer.purchases)) {
    customer.purchases.forEach((purchase) => {
      if (
        purchase.subscription_id === null &&
        purchase.status !== "pending" &&
        purchase.status !== "active"
      ) {
        if (!isNaN(parseFloat(purchase.amount))) {
          total += parseFloat(purchase.amount);
        }
      }
    });
  }
  return total;
};

export const grayImg = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIwIiBoZWlnaHQ9IjMyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiLz4=';

export const getValue = (object, key, type) => {
  if (object && object !== null) {
    const value = object[key];

    if (value && value !== null && value !== "") {
      if (type === "boolean") {
        return value === true || parseInt(value) === 1;
      } else if (type === "date") {
        
        return getFriendlyLocalDateString(value);
      }
      return value;
    }
  }
  if (type === "boolean") return false;
  return "";
};
