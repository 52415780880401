import React, { createContext, useContext, useReducer } from "react";
import ClassInstructorReducer from "../reducers/ClassInstructorReducer";
import ClassInstructorService from "../services/ClassInstructorService";
import {
  CREATE_CLASE,
  SEMANAS_RECIBIDAS,
  SET_CLASE,
  SET_PROPIEDAD_CLASE,
  CLASES_RECIBIDAS,
  TOTAL_RECIBIDO,
  SHOW_SPINNER,
  HIDE_SPINNER,
  ADD_INSTRUCTOR,
  REMOVE_INSTRUCTOR,
  CLEAR_SCHEDULE_UPDATE,
  SET_SCHEDULE_UPDATE,
  SET_MONTH,
  SET_WEEK,
  SET_START_DATE,
  SET_END_DATE,
  ADD_TAG,
  REMOVE_TAG,
  SET_VIEW,
} from "../types";
import { ModalContext } from "./ModalContext";

const initialState = {
  instructorClass: null,
  selectedWeek: null,
  class_types: null,
  start_date: null,
  end_date: null,
  spinner: false,
  view: "week",
  update: true,
  clases: null,
  clase: null,
  month: null,
  weeks: null,
};

export const SingleClassContext = createContext(initialState);

export const ClassInstructorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ClassInstructorReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const setMonth = (month) => {
    dispatch({ type: SET_MONTH, payload: month });
  };

  const setWeek = (week) => {
    dispatch({ type: SET_WEEK, payload: week });
  };

  const getClases = (start_date, end_date) => {
    dispatch({ type: SHOW_SPINNER });
    ClassInstructorService.getClases(start_date, end_date).then((res) => {
      const { clases, total } = res.data;
      dispatch({ type: CLASES_RECIBIDAS, payload: clases });
      dispatch({ type: TOTAL_RECIBIDO, payload: total });
      dispatch({ type: HIDE_SPINNER });
    });
  };

  const getAsistentes = (class_instructor_id) => {
    ClassInstructorService.getAsistentes(class_instructor_id).then((res) => {
      const { clase } = res.data;
      dispatch({ type: SET_CLASE, payload: clase });
    });
  };

  const getSchedule = (params) => {
    ClassInstructorService.getWeeks(params).then(
      (res) => {
        const { days } = res.data;
        dispatch({ type: SEMANAS_RECIBIDAS, payload: days });
      }
    );
  };

  const getAdminSchedule = (start_date, end_date) => {
    ClassInstructorService.getAdminWeeks(start_date, end_date).then((res) => {
      const { days } = res.data;
      dispatch({ type: SEMANAS_RECIBIDAS, payload: days });
    });
  };

  const getClase = (class_instructor_id) => {
    ClassInstructorService.getClase(class_instructor_id).then((res) => {
      const { clase } = res.data;
      dispatch({ type: SET_CLASE, payload: clase });
    });
  };

  const createClase = () => {
    dispatch({ type: CREATE_CLASE });
  };

  const setClase = (clase) => {
    dispatch({ type: SET_CLASE, payload: clase });
  };

  const setPropiedadClase = (key, value) => {
    dispatch({ type: SET_PROPIEDAD_CLASE, payload: { key, value } });
  };

  const addInstructor = (instructor) => {
    dispatch({ type: ADD_INSTRUCTOR, payload: instructor });
  };

  const removeInstructor = (instructor_id) => {
    dispatch({ type: REMOVE_INSTRUCTOR, payload: instructor_id });
  };

  const addTag = (tag) => {
    dispatch({ type: ADD_TAG, payload: tag });
  };

  const removeTag = (tag_id) => {
    dispatch({ type: REMOVE_TAG, payload: tag_id });
  };

  const postClase = (clase, callback) => {
    const handleSuccess = () => {
      if (!window.location.pathname.includes("asistentes")) {
        dispatch({ type: SET_CLASE, payload: null });
      }
      dispatch({ type: SET_SCHEDULE_UPDATE });
      success("¡Clase guardada con éxito!");
      if(typeof callback === "function") {
        callback();
      }
      clearModal();
    };
    if (isNaN(clase.single_class_id)) {
      ClassInstructorService.postClase(clase).then(handleSuccess);
    } else {
      ClassInstructorService.putClase(clase).then(handleSuccess);
    }
  };

  const clearClase = () => {
    dispatch({ type: SET_CLASE, payload: null });
  };

  const eliminarClase = (single_class_id, callback) => {
    ClassInstructorService.deleteClase(single_class_id).then(() => {
      success("¡Clase eliminada con éxito!");
      if (typeof callback === "function") {
        callback();
      }
      clearModal();
    });
  };

  const postAsistenteClase = ({
    customer_id,
    single_class_id,    
    payment_method_id,
    is_paid,
    spot,
  }) => {
    ClassInstructorService.postAsistenteClase({
      customer_id,
      single_class_id,
      payment_method_id,
      is_paid,
      spot,
    })
      .then(() => {
        getAsistentes(single_class_id);
        success("¡Asistente agregado!");
        clearModal();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 412) {
            alert("Lo sentimos, esta clase ya esta llena.");
          }
        }
      });
  };

  const postPayment = (class_reservation_id, is_paid, class_instructor_id) => {
    ClassInstructorService.postPayment(class_reservation_id, is_paid).then(
      () => {
        success(is_paid ? "Pago registrado." : "Pago cancelado.");
        getAsistentes(class_instructor_id);
      }
    );
  };

  const clearUpdate = () => {
    dispatch({ type: CLEAR_SCHEDULE_UPDATE });
  };

  const setStartDate = (start_date) => {
    dispatch({ type: SET_START_DATE, payload: start_date });
  };

  const setEndDate = (end_date) => {
    dispatch({ type: SET_END_DATE, payload: end_date });
  };

  const clearSemanas = () => {
    dispatch({ type: SEMANAS_RECIBIDAS, payload: null });
  };

  const setView = (view) => {
    dispatch({ type: SET_VIEW, payload: view });
  };

  return (
    <SingleClassContext.Provider
      value={{
        ...state,
        addTag,
        setView,
        setWeek,
        setClase,
        getClase,
        setMonth,
        removeTag,
        getClases,
        postClase,
        clearClase,
        setEndDate,
        postPayment,
        getSchedule,
        createClase,
        clearUpdate,
        setStartDate,
        clearSemanas,
        eliminarClase,
        getAsistentes,
        addInstructor,
        getAdminSchedule,
        removeInstructor,
        setPropiedadClase,
        postAsistenteClase,
      }}
    >
      {children}
    </SingleClassContext.Provider>
  );
};
