
import { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";


const TrackingCodesInputs = () => {
  const {
    head_tracking_codes,
    body_tracking_codes,
    setAppConfigProperty
  } = useContext(AppConfigContext);

  
  return(
    <>
      <div className="row w-100 m-auto mb-3">
        <h5 className="mb-1 px-0">Head Tracking Codes</h5>
        <textarea 
          className="form-control"
          style={{height: '150px'}}
          value={head_tracking_codes}
          onChange={(event) => {
            setAppConfigProperty('head_tracking_codes', event.target.value);
          }}
        />
      </div>

      <div className="row w-100 m-auto mb-3">
        <h5 className="mb-1 px-0">Body Tracking Codes</h5>
        <textarea 
          className="form-control"
          style={{height: '150px'}}
          value={body_tracking_codes}
          onChange={(event) => {
            setAppConfigProperty('body_tracking_codes', event.target.value);
          }}
        />
      </div>
    </>  
  )
}

export default TrackingCodesInputs;