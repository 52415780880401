import React, { useContext, useEffect, useState } from "react";
// import DesignInputs from "../../components/ajustes/DesignInputs";
// import AppImagesInputs from "../../components/ajustes/AppImagesInputs";
import TrackingCodesInputs from "../../components/ajustes/TrackingCodesInputs";
import FilesLocationInput from "../../components/ajustes/FilesLocationInputs";
import AppearanceSection from "../../components/ajustes/AppearanceSection";
import useCrudSettingsPages from "../../hooks/pages/useCrudSettingsPages";
import TimeFrameInputs from "../../components/ajustes/TimeFrameInput";
import ContactSection from "../../components/ajustes/ContactSection";
import { AppConfigContext } from "../../context/AppConfigContext";
import LegalSection from "../../components/ajustes/LegalSection";
import { PackagesContext } from "../../context/PackageContext";
import HomeSection from "../../components/ajustes/HomeSection";
import SecureInput from "../../components/ajustes/SecureInput";
import useCrudAppConfig from "../../hooks/useCrudAppConfig";
import PanelTitle from "../../components/global/PanelTitle";
import { UserContext } from "../../context/UserContext";
import ReactSelect from "react-select";
import moment from "moment-timezone";

const tabs = [
  {
    key: 'home',
    label: 'Home',
  },
  {
    key: 'appearance',
    label: 'Aspecto',
  },
  {
    key: 'contact',
    label: 'Contacto',
  },
  {
    key: 'classes',
    label: 'Clases Presenciales',
  },
  {
    key: 'marketing',
    label: 'Marketing',
  },
  {
    key: 'legal',
    label: 'Legal',
  },
  {
    key: 'advanced',
    label: 'Avanzado',
  },
];

const AdminAjustes = () => {
  const [availablePackages, setAvailablePackages] = useState([]);
  const [currentPackage, setCurrentPackage] = useState(null);
  const [currentTab, setCurrentTab] = useState('home');
  const { 
    loading,
    timezone,
    setAppConfigProperty,
    signup_bonus,
    stripe_secret,
    stripe_webhook_secret,
    stripe_public_key,
    brevo_api_key,
    email_from_address,
    email_from_name
  } = useContext(AppConfigContext);

  const { saveAppConfig } = useCrudAppConfig();
  const { getPaquetes, paquetes } = useContext(PackagesContext);
  const { user } = useContext(UserContext);
  const { getPrivacyPoliticsPage, getTermsPage } = useCrudSettingsPages();

  useEffect(() => {
    getLegalSectionData();
    getPaquetes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLegalSectionData = async () => {
    const privacyPage = await getPrivacyPoliticsPage();
    const termsPage = await getTermsPage();

    setAppConfigProperty('privacy_policy', privacyPage);
    setAppConfigProperty('terms_and_conditions', termsPage);
  }

  useEffect(() => {
    const data = paquetes?.map(obj => {
      return {
        value: obj.class_package_id,
        label: obj.title,
      }
    })

    const currentIndex = data?.findIndex(obj => {
      return obj.value === Number(signup_bonus);
    })

    if(currentIndex >= 0) setCurrentPackage(data[currentIndex])

    setAvailablePackages(data);
  }, [paquetes, signup_bonus]);

  const handleSaveConfig = async () => {
    await saveAppConfig();
  }

  const handleChangePackage = (data) => {
    setAppConfigProperty('signup_bonus', data.value);
  }

  const renderHome = () => {
    if(currentTab === 'home') {
      return(
        <HomeSection/>
      )
    }
  }

  const renderAppearance = () => {
    if(currentTab === 'appearance') {
      return(
        <AppearanceSection/>
      )
    }
  }

  const renderClasses = () => {
    if(currentTab === 'classes') {
      return  <TimeFrameInputs/>
    }
  }

  const renderMarketing = () => {
    if(currentTab === 'marketing') {
      return(
        <div className="col-12 ">
          <h5 className="mb-1">Paquete de Regalo de bienvenida</h5>

          <ReactSelect
            className="mb-3"
            value={currentPackage}
            options={availablePackages}
            onChange={newValue => handleChangePackage(newValue)} 
          />

          <h5 className="mb-1">Mailing</h5>

          <div className="row mb-3">
            <div className="col-6">
              <label className="bold">Email</label>
              <input
                className="form-control"
                value={email_from_address}
                onChange={(event) => {
                  setAppConfigProperty("email_from_address", event.target.value);
                }}
              />
            </div>
            <div className="col-6">
            <label className="bold">Nombre</label>
              <input
                className="form-control"
                value={email_from_name}
                onChange={(event) => {
                  setAppConfigProperty("email_from_name", event.target.value);
                }}
              />
            </div>
          </div>

          <TrackingCodesInputs/>

        </div>
      )
    }
  }

  const renderLegal = () => {
    if(currentTab === 'legal') {
      return <LegalSection/>
    }
  }

  const renderAdvancedSuperAdmin = () => {
    
    if(currentTab === 'advanced' && user.role === 'super_admin') {
      const timezones = [{ label: "Seleccionar...", value: ""}, ...moment.tz.zonesForCountry("MX").map(name => ({ label: name, value: name }))];
      return(
        <div className="col-12 px-0">
          <div className="row mx-auto mb-3">
            <SecureInput
              smallTitle
              hideValue
              type={'select'}
              value={timezone}
              label={'Zona Horaria'}
              selectOptions={timezones}
              handleChange={(event) => {
                setAppConfigProperty('timezone', event.target.value);
              }}
            />
          </div>

          <FilesLocationInput/>

          <div className="row mx-auto mb-3">
            <SecureInput
              smallTitle
              type={'text'}
              hideValue
              label={'Stripe Secret'}
              value={stripe_secret}
              handleChange={(event) => {
                setAppConfigProperty('stripe_secret', event.target.value);
              }}
            />
          </div>

          <div className="row mx-auto mb-3">
            <SecureInput
              smallTitle
              type={'text'}
              hideValue
              label={'Stripe WebHook Secret'}
              value={stripe_webhook_secret}
              handleChange={(event) => {
                setAppConfigProperty('stripe_webhook_secret', event.target.value);
              }}
            />
          </div>

          <div className="col-12 mx-auto px-0 mb-3">
            <SecureInput
              smallTitle
              type={'text'}
              hideValue
              label={'Stripe Public Key'}
              value={stripe_public_key}
              handleChange={(event) => {
                setAppConfigProperty('stripe_public_key', event.target.value);
              }}
            />
          </div>

          <div className="col-12 mx-auto px-0 mb-3">
            <SecureInput
              smallTitle
              type={'text'}
              hideValue
              label={'Brevo API Key'}
              value={brevo_api_key}
              handleChange={(event) => {
                setAppConfigProperty('brevo_api_key', event.target.value);
              }}
            />
          </div>
        </div>
      )
    }
  }

  const renderAdvanced = () => {
    if(currentTab === 'advanced' && user.role !== 'super_admin') {
      return (
        <>
          <div className="col-12 mx-auto px-0 mb-3">
            <SecureInput
              smallTitle
              type={'text'}
              hideValue
              label={'Stripe Public Key'}
              value={stripe_public_key}
              handleChange={(event) => {
                setAppConfigProperty('stripe_public_key', event.target.value);
              }}
            />
          </div>
        </>
      )
    }
  }

  const renderContact = () => {
    if(currentTab === 'contact') {
      return (
        <ContactSection/>
      )
    }
  }

  const renderNav = () => {
    return (
      <ul 
        className="nav nav-tabs" 
        style={{
          overflowX: 'auto',
          overflowY: 'hidden',
          height: '57px', 
          flex: '0 0 auto',
          maxHeight: '57px',
          flexWrap: 'nowrap'
        }}
      >
        {
          tabs.map((tab, index) => {
            return(
              <li 
                key={tab.key} 
                className="nav-item"
                style={{
                  width: 'max-content'
                }}
              >
                <button 
                  style={{
                    width: 'max-content'
                  }}
                  className={`nav-link 
                    ${tab.key === currentTab ? 'active text-primary' : 'text-dark'}
                  `}
                  aria-current="page" 
                  onClick={() => {
                    setCurrentTab(tab.key);
                  }}
                >
                  {tab.label}
                </button>
              </li>
            )
          })
        }
      </ul>
    )
  }

  return (
    <div className="contiainer-fluid px-3 position-relative vh-100 overflow-hidden">
      <PanelTitle title="Ajustes" hideButton  />

      <div 
        className="card position-relative overflow-hidden" 
        style={{
          cursor: 'unset',
          height: 'calc(100% - 90px)'
        
        }}
      >
        {renderNav()}

        <div 
          className="row w-100 m-auto mt-3 overflow-auto mb-3 "
          style={{
            flex: 1
            // maxHeight: 'calc(100% - 60px)'
          }}
        >
          {renderHome()}
          {renderAppearance()}
          {renderContact()}
          {renderClasses()}
          {renderMarketing()}
          {renderLegal()}
          {renderAdvancedSuperAdmin()}
          {renderAdvanced()}
        </div>

        <div className="row w-100 mx-auto">
          <div className="col-12 d-flex justify-content-end">
            <button 
              type="button"
              className="btn btn-primary"
              onClick={handleSaveConfig}
              disabled={loading}
              style={{
                maxWidth: 'max-content'
              }}
            >
              Guardar Configuración
            </button>
          </div>
        </div> 

      </div>
      
    </div>
  );
};

export default AdminAjustes;
