import { useContext } from "react";
import { AppConfigContext } from "../context/AppConfigContext";
import AdjuntosService from "../services/AdjuntosService";
import { AdjuntosContext } from "../context/AdjuntosContext";
import { PagesContext } from "../context/PagesContext";

const generalConfig = [
  'business_name',
  'tagline',
  'primary',
  'accent',
  'dark',
  'light',
  'gray',
  'signup_bonus',
  'cancel_timeframe_value',
  'cancel_timeframe_unit',
  'reservation_timeframe_hours',
  'reservation_timeframe_minutes',
  'files_location',
  'head_tracking_codes',
  'body_tracking_codes',
  'stripe_secret',
  'stripe_webhook_secret',
  'stripe_public_key',
  'allow_class_guess',
  'location',
  'email',
  'facebook',
  'instagram',
  'tiktok',
  'twitter',
  'youtube',
  'pinterest',
  'linkedin',
  'whatsapp',
  'phone',
  'whatsapp_widget',
  'brevo_api_key',
  'email_from_address',
  'email_from_name',
  'hero_title',
  'hero_tagline',
  'hero_enabled',
  'about_enabled',
  'class_types_enabled',
  'coaches_enabled',
  'packages_enabled',
  'single_class_enabled',
  'hero_order',
  'about_order',
  'class_types_order',
  'coaches_order',
  'packages_order',
  'single_class_order',
  'hero_resource',
  'hero_video',
  'hero_image',
  'app_css',
  'timezone',
]

const useCrudAppConfig = () => {
  const appconfig = useContext(AppConfigContext);

  const {
    fileConfigChanged,
    images_inputs,
    privacy_policy,  
    terms_and_conditions, 

    setAppConfigProperty,
    updateAppConfig,
  } = appconfig;

  const { testAdjunto } = useContext(AdjuntosContext);
  const { postPage } = useContext(PagesContext);

  const uploadFiles = async (filesData) => {
    const files = [];

    if(Array.isArray(filesData) && filesData.length > 0) {
      await Promise.all(
        filesData.map(async (input) => {
          return new Promise(async (resolve, reject) => {
            try {
              if(input.file) {
                const formData = AdjuntosService.getFormData(input.file);
                const fileData = (await AdjuntosService.postAdjunto(formData)).data.file;
                const fileName = `${fileData?.name}.${fileData.type}`;
  
                files.push({
                  key: input.key,
                  fileName
                });
              }
              resolve();
            } catch (error) {
              reject(error);
            }
          })
        })
      );
    }

    return files;
  }

  const saveAppConfigPages = async () => {
    await postPage(terms_and_conditions);
    await postPage(privacy_policy);
  }

  const saveAppConfig = async () => {
    setAppConfigProperty('loading', true);

    if(fileConfigChanged) {
      const result = await testAdjunto();
      if(!result) return;
    }

    const newFiles = await uploadFiles(images_inputs);

    const data = {}

    generalConfig.forEach(configKey => {
      data[configKey] = appconfig[configKey];
    });

    newFiles.forEach(file => {
      data[file.key] = file.fileName;
    })

    await updateAppConfig(data);  
    await saveAppConfigPages()
    setAppConfigProperty('loading', false);
  }


  return {
    saveAppConfig
  };
}

export default useCrudAppConfig;