import { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import ReactSwitch from "react-switch";

const TimeFrameInputs = () => {
  const {
    setAppConfigProperty,
    cancel_timeframe_unit,
    cancel_timeframe_value,
    reservation_timeframe_hours,
    reservation_timeframe_minutes,
    allow_class_guess,
  } = useContext(AppConfigContext);

  return (
    <div className="col-12">
      <div className="row w-100">
        <div className="col-12 ">
          <h5 className="mb-1">Tiempo de tolerancia de cancelación</h5>
        </div>

        <div className="col-12 d-flex">
          <input
            type={"number"}
            className="form-control me-2"
            style={{
              maxWidth: "max-content",
            }}
            value={cancel_timeframe_value}
            onChange={(event) =>
              setAppConfigProperty("cancel_timeframe_value", event.target.value)
            }
          />

          <select
            className="form-control"
            style={{
              maxWidth: "max-content",
            }}
            value={cancel_timeframe_unit}
            onChange={(event) =>
              setAppConfigProperty("cancel_timeframe_unit", event.target.value)
            }
          >
            <option value={"days"}>Días</option>
            <option value={"hours"}>Horas</option>
            <option value={"minutes"}>Minutos</option>
          </select>
        </div>

        <div className="col-12 mt-4">
          <h5 className="mb-1">
            Tiempo minimo de reservación en clases presenciales
          </h5>
        </div>

        <div className="col-12 mb-3">
          <div className="row">
            <div style={{ maxWidth: "125px" }} className="col-6 pe-0">
              <label className="small">Horas</label>
              <input
                type="number"
                className="form-control"
                value={reservation_timeframe_hours}
                onChange={(event) =>
                  setAppConfigProperty(
                    "reservation_timeframe_hours",
                    event.target.value
                  )
                }
                max={23}
                min={0}
              />
            </div>

            <div style={{ maxWidth: "125px" }} className="col-6 pe-0">
              <label className="small">Minutos (0 - 59)</label>
              <input
                type="number"
                className="form-control"
                value={reservation_timeframe_minutes}
                max={59}
                min={0}
                onChange={(event) =>
                  setAppConfigProperty(
                    "reservation_timeframe_minutes",
                    event.target.value
                  )
                }
              />
            </div>
          </div>
        </div>

        <div className="row w-100">
          <h5 className="mb-1">Permitir Invitados</h5>
          <ReactSwitch
            checked={parseInt(allow_class_guess) ? true : false}
            onChange={(checked) => {
              let value;
              checked ? (value = 1) : (value = 0);
              setAppConfigProperty("allow_class_guess", value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TimeFrameInputs;
