import React, { useContext } from "react";
import { Link } from "@reach/router";
import { formatMonto } from "../../utils";
import { ModalContext } from "../../context/ModalContext";
import PurchaseRefundForm from "../purchases/PurchaseRefundForm";
import { getFriendlyLocalDateTimeString, getLocalMomentObject } from "../../utils/dates";

const InvoiceRow = ({ user, invoice, customer_id }) => {

  const { modalComponent, clearModal } = useContext(ModalContext);
  
  
  const renderPaymentMethod = () => {
    if (invoice.purchase && invoice.purchase !== null) {
      if (
        invoice.purchase.payment_method &&
        invoice.purchase.payment_method !== null
      ) {
        return invoice.purchase.payment_method.name;
      }
    }
    return "N/A";
  };

  const renderSiguienteCargo = () => {
    if (invoice.purchase && invoice.purchase !== null) {
      if (
        invoice.purchase.status !== "cancelled" &&
        invoice.purchase.status !== "pending" &&
        invoice.purchase.subscription_id !== null &&
        invoice.status !== "failed"
      ) {
        const initial = getLocalMomentObject(invoice.createdAt);
        if (
          invoice.purchase.free_trial_length !== null &&
          invoice.purchase.free_trial_length &&
          getLocalMomentObject(invoice.createdAt).isBefore(
            getLocalMomentObject(invoice.purchase.createdAt).add(
              invoice.purchase.free_trial_length,
              "days"
            )
          )
        ) {
          initial.add(invoice.purchase.free_trial_length, "days");
        } else {
          initial.add(
            invoice.purchase.subscription_interval,
            invoice.purchase.subscription_period
          );
        }
        return initial.format("DD MMM YYYY");
      }
    }
  };

  const renderClassPlackage = () => {
    if (invoice.class_package !== null) {
      return invoice.class_package.title;
    }
  };

  const handleRefund = () => {
    modalComponent(
      "Reembolsar Compra",
      <PurchaseRefundForm 
        invoice={invoice}
        handleCancel={() => {
          clearModal();
        }}
      />
    )
  }

  const renderRefundBtn = () => {
    if(invoice.status === 'completed') {
      return(
        <li>
          <button
            className="dropdown-item px-2 "
            onClick={handleRefund}
          >
            <i className="fas fa-undo text-success me-2" style={{width: '20px', height: '20px'}}/>
            Reembolso
          </button>
        </li>
      )
    }
    
  }

  const renderActions = () => {
    if(invoice.status === 'completed') {
      return (
        <div className="dropdown">
          <button
            className="btn btn-outline-primary border border-1 dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Opciones
          </button>
  
          <ul className="dropdown-menu py-0">
        
            {renderRefundBtn()}
          </ul>
        </div>
      );
    }
  };

  return (
    <tr className="hover-light border-bottom small align-middle">
      <td className="id-td">{invoice.invoice_id}</td>
      <td className="id-td">
        {invoice.purchase_id !== null ? invoice.purchase_id : "N/A"}
      </td>
      <td>{getFriendlyLocalDateTimeString(invoice.createdAt)}</td>
      <td>{renderClassPlackage()}</td>
      {!customer_id && (
        <td>
          <Link to={`/myadmin/customer/${invoice.customer_id}`}>
            {invoice.customer.name} {invoice.customer.last_name}{" "}
          </Link>
        </td>
      )}
      <td>
        {["admin", "super_admin"].includes(user.role) && (
          <>
            {"$"}
            {formatMonto(invoice.amount)}
          </>
        )}
      </td>
      <td>
        {invoice.status === "completed" ? (
          <span className="badge badge-pill bg-success">Completado</span>
        ) : invoice.status === "failed" ? (
          <span className="badge badge-pill bg-danger">Fallido</span>
        ) : (
          <span className="badge badge-pull bg-secondary text-capitalize">
            {invoice.status}
          </span>
        )}
      </td>
      <td>{renderSiguienteCargo()}</td>

      <td colSpan={2}>{renderPaymentMethod()}</td>
      <td>
        {renderActions()}
      </td>
    </tr>
  );
};

export default InvoiceRow;
